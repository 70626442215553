import axios from 'axios'
import VueAxios from 'vue-axios'
import { getToken } from './storage'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

const onResponseSuccess = (response) => {
  return response
}

const onResponseError = error => {
  let message = null
  let caption = null
  const status = error?.response?.status
  switch (status) {
    case 422: {
      const errors = Object.values(error?.response?.data).map(e => {
        return Array.isArray(e) ? e[0] : e
      })
      message = errors.join('<br>')
      break
    }
    case 400: {
      message = error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.response?.statusText
      break
    }
    default: {
      message = 'Falha ao processar'
      caption = `Code: ${status}`
      console.error(error.response)
    }
  }

  console.log({ message, caption })

  return Promise.reject(error)
}

axios.interceptors.request.use(
  function (config) {
    config.headers.Accept = 'application/json'
    config.headers['Content-Type'] = 'application/json'
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  onResponseSuccess,
  onResponseError
)

export {
  axios,
  VueAxios
}
