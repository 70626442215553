
export default [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/pages/dashboard/index.vue')
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../views/pages/clients/clients.vue')
  },
  {
    path: '/clients-grid',
    name: 'clients-grid',
    component: () => import('../views/pages/clients/clients-grid')
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import('../views/pages/my-account/my-account-form')
  },
  {
    path: '/website-config',
    name: 'website_config',
    component: () => import('../views/pages/my-account/website-config')
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/pages/reports/index')
  },
  {
    path: '/raffles-list',
    name: 'raffles-list',
    component: () => import('../views/pages/raffles/raffles-list')
  },
  {
    path: '/partner-links/:contestId?',
    name: 'partner-links',
    component: () => import('../views/pages/partner-links/partner-links')
  },
  {
    path: '/contests/:id/orders',
    name: 'contest_orders',
    component: () => import('@/views/pages/contests/orders')
  },
  {
    path: '/raffles-form/create',
    name: 'raffles-create',
    component: () => import('../views/pages/raffles/rafflesForm')
  },
  {
    path: '/raffles-form/update/:id',
    name: 'raffles-update',
    component: () => import('../views/pages/raffles/rafflesForm')
  },
  {
    path: '/raffles-form/orders/:id',
    name: 'raffles-orders',
    component: () => import('../views/pages/raffles/raffleOrdersList')
  },
  {
    path: '/raffles-form/management/:id',
    name: 'raffles-management',
    component: () => import('../views/pages/raffles/rafflesManagement')
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../views/pages/auth/login.vue')
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import('../views/pages/auth/register.vue')
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: () => import('../views/pages/auth/logout.vue')
  },
  {
    path: '/auth/lock-screen',
    name: 'lock-screen',
    component: () => import('../views/pages/auth/lock-screen.vue')
  },
  {
    path: '/bank-accounts',
    name: 'bank-accounts',
    component: () => import('../views/pages/bank-account/bank-account-list')
  },
  {
    path: '/wizard/:stage',
    name: 'wizard_stages',
    component: () => import('@/views/pages/wizard/layout'),
  },
]
