import Swal from 'sweetalert2'

const _defaults = {
  position: 'top-end',
  icon: 'success',
  showConfirmButton: false,
  timer: 1500,
}

const fireType = (params, icon) => {
  const text = typeof params === 'string' ? params : undefined

  const options = {
    ..._defaults,
  }

  if (typeof params === 'object') {
    Object.assign(options, params)
  }

  Object.assign(options, {
    text,
    icon
  })

  return Swal.fire(options)
}

export default class Notify {
  static create(params) {
    let title = typeof params === 'string' ? params : undefined
    const options = {
      position: 'center',
      icon: undefined,
      title,
      showConfirmButton: true,
      timer: false
    }

    if (typeof params === 'object') {
      Object.assign(options, params)
    }

    return Swal.fire(options)
  }

  static success(params) {
    fireType(params, 'success')
  }

  static error(params) {
    fireType(params, 'error')
  }

  static warning(params) {
    fireType(params, 'warning')
  }

  static info(params) {
    fireType(params, 'info')
  }
}
