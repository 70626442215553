<template>
  <div
    style="width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 9999;
    position: absolute;
    opacity: .6;
  ">
    <div
      style="margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff; 
    ">
      <div style="text-align: center; margin-bottom: 1rem;">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div style="text-align: center;">
        <b-spinner
          v-if="spinner"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InnerElement',
  props: {
    title: String,
    spinner: {
      type: [Boolean, String, null]
    }
  }
}
</script>
