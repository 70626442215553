import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import storage from '@/storage'
import store from '@/state/store'
import Notify from '@/notify'
import router from '@/router'

window.Pusher = Pusher

let echo = null

const startEcho = (token) => {
  return new Promise((resolve) => {
    echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_APP_KEY,
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      forceTLS: true,
      authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
      auth: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    })
    window.Echo = echo
    resolve(echo)
  })
}

window.Echo = echo
let userListening = false

const setupUserEvents = () => {
  const user = storage.getItem('user')
  const token = storage.getItem('token')
  if (!userListening) {
    if (
      user && typeof user === 'object' &&
      token && typeof token === 'string' && token.length > 10
    ) {
      startEcho(token).then((echo) => {
        echo.channel(`private-users.${user.id}`)
          .listen('.contest.deleted', () => {
            Notify.create({
              title: 'Sorteio excluído com sucesso!',
              position: "top-right",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            })
          })
          .listen('.payment.requested', (data) => {
            store.dispatch('app/setContestPaymentRequest', data)
          })
          .listen('.payment.confirmed', (data) => {
            Notify.create({
              icon: 'success',
              html: `Seu sorteio <strong> ${data.title} </strong> acaba de ser publicado!`,
              confirmButtonText: 'Gerenciar este sorteio'
            }).then(({ isConfirmed }) => {
              /**
               * bug: barra de rolagem não exibida
               * body style="overflow: hidden; padding-right: 15px";
               */
              document.body.removeAttribute('style')
              if (isConfirmed) {
                router.push({
                  name: 'raffles-management',
                  params: { id: data.id }
                })
              }
            })
          })

        userListening = true
      })
    }
  }
}

setupUserEvents()

export { echo, setupUserEvents }
