<template>
  <component
    :is="'button'"
    @click="showUpgradeScreen"
  >
    <slot name="icon" />
    <i :class="icon" />
    {{ title }}
    <i :class="iconRight" />
  </component>
</template>

<script>
import { useStore } from 'vuex'

export default {
  name: 'UpgradeTrigger',
  props: {
    title: String,
    icon: [String, null],
    iconRight: [String, null],
  },
  setup() {
    const store = useStore()

    const showUpgradeScreen = () => store.dispatch('app/showUpgradeScreen')

    return {
      showUpgradeScreen
    }
  }
}
</script>
