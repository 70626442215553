import { createWebHistory, createRouter } from "vue-router";

import routes from "./routes";
import { getToken } from "../storage";
import { checkAccount } from '@/feature-check'

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE ?? ''),
  routes,
  // mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ['login', 'logout', 'lock-screen', 'register'];
  const authpage = !publicPages.includes(routeTo.name);
  const authToken = getToken()
  const isValidToken = typeof authToken === 'string' && authToken.length > 10

  if (authpage && !isValidToken) {
    return next({ name: 'login' });
  }

  checkAccount()

  next();
});

export default router;
