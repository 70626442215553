import { axios } from './axios'
import store from '@/state/store'
import { getToken } from './storage'

export const FEATURE = {
  CONTEST_PROMOTIONS: 'contest-promotions'
}

export const checkAccount = (force = false) => {
  const authToken = getToken()
  const isValidToken = typeof authToken === 'string' && authToken.length > 10

  if (!isValidToken) {
    return Promise.resolve(null)
  }

  if (store.state.app.isChecked && !force) {
    return Promise.resolve(store.state.app)
  }

  return axios.get('/seller/account').then(({ data }) => {
    store.dispatch('app/setChecked', true)
    store.dispatch('app/setFreeAccount', data.free)
    store.dispatch('app/setLicensedAccount', data.is_legal_seller)
    store.dispatch('app/setPremiumFeatures', data.premium_features)
    store.dispatch('app/setUpgradeWhatsapp', data.whatsapp)
    return checkAccount()
  })
}

export const isFeatureEnabled = () => {
  return !store.state.app.isFreeAccount
}

export const featureLimit = (feature) => {
  if (isFeatureEnabled(feature)) {
    return Number.MAX_SAFE_INTEGER
  }

  switch(feature) {
    case FEATURE.CONTEST_PROMOTIONS:
      return 1
  }

  return 0
}

export const contestPromitionsLimit = () => featureLimit(FEATURE.CONTEST_PROMOTIONS)

export const showUpgradeScreen = () => store.dispatch('app/showUpgradeScreen')

export const isFreeAccount = () => store.state.app.isFreeAccount

export const isLicensedAccount = () => store.state.app.isLicensedAccount

export const showContestPayment = (data) => store.dispatch('app/setContestPaymentRequest', data)
