export const state = {
  isChecked: false,
  isFreeAccount: false,
  isLicensedAccount: false,
  showUpgradeScreen: false,
  upgradeWhatsapp: null,
  premiumFeatures: {},
  contestPaymentRequest: null
}

export const mutations = {
  SHOW_UPGRADE_SCREEN(state) {
    state.showUpgradeScreen = true
  },
  HIDE_UPGRADE_SCREEN(state) {
    state.showUpgradeScreen = false
  },
  TOGGLE_UPGRADE_SCREEN(state) {
    state.showUpgradeScreen = !state.showUpgradeScreen
  },
  SET_CHECKED(state, value) {
    state.isChecked = value
  },
  SET_FREE_ACCOUNT(state, value) {
    state.isFreeAccount = value
  },
  SET_LICENSED_ACCOUNT(state, value) {
    state.isLicensedAccount = value
  },
  SET_PREMIUM_FEATURES(state, features) {
    state.premiumFeatures = Object.assign({}, features)
  },
  SET_UPGRADE_WHATSAPP(state, whatsapp) {
    state.upgradeWhatsapp = whatsapp
  },
  SET_CONTEST_PAYMENT_REQUEST(state, paymentRequest) {
    state.contestPaymentRequest = paymentRequest
  },
}

export const actions = {
  showUpgradeScreen({ commit }) {
    commit('SHOW_UPGRADE_SCREEN')
  },
  hideUpgradeScreen({ commit }) {
    commit('HIDE_UPGRADE_SCREEN')
  },
  toggleUpgradeScreen({ commit }) {
    commit('TOGGLE_UPGRADE_SCREEN')
  },
  setChecked({ commit }, value) {
    commit('SET_CHECKED', value)
  },
  setFreeAccount({ commit }, value) {
    commit('SET_FREE_ACCOUNT', value)
  },
  setLicensedAccount({ commit }, value) {
    commit('SET_LICENSED_ACCOUNT', value)
  },
  setPremiumFeatures({ commit }, features) {
    commit('SET_PREMIUM_FEATURES', features)
  },
  setUpgradeWhatsapp({ commit }, whatsapp) {
    commit('SET_UPGRADE_WHATSAPP', whatsapp)
  },
  setContestPaymentRequest({ commit }, paymentRequest) {
    commit('SET_CONTEST_PAYMENT_REQUEST', paymentRequest)
  }
}
