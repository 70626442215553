import {
  createApp
} from "vue";
import App from "./App.vue";
import * as EvaIcons from "@stefandesu/eva-icons-vue"
import router from "./router";
import ActionCableVue from "actioncable-vue";
import BootstrapVue3 from 'bootstrap-vue-3';
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3"
import Maska from 'maska'
import i18n from './i18n'
import money from 'v-money3'
import vSelect from 'vue-select'
import { isFeatureEnabled } from './feature-check'
import UpgradeTrigger from '@/components/upgrade-trigger'
import InnerElement from '@/components/inner-element'

import VueTheMask from "vue-the-mask";
import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';
import './assets/scss/custom/plugins/mermaid.min.css'
import 'vue-select/dist/vue-select.css';

import store from "./state/store";
import { axios, VueAxios } from "./axios";
import './boot/pusher'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import "./assets/scss/app.scss";

const websocketOptions = {
  debug: true,
  debugLevel: "error",
  connectionUrl: "http://localhost:8080/",
  connectImmediately: false
};

createApp(App)
  .use(store)
  .use(money)
  .use(VueTheMask)
  .use(i18n)  
  .use(EvaIcons)
  .use(router)
  .use(BootstrapVue3)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(Maska)
  .use(ActionCableVue, websocketOptions)
  .use(VueAxios, axios)
  .component('VueDatePicker', VueDatePicker)
  .mixin({
    methods: {
      isFeatureEnabled,
      maskPhone: (phone) => {
        return phone
          .replace(/\D/g, '')
          .replace(/^(\d{2})(\d)/g, '($1) $2')
          .replace(/(\d)(\d{4})$/, '$1 $2')
      },
      whatsappLink: (name, whatsapp) => {
        const message = `Olá ${name}, tudo bem?`;
        return `https://api.whatsapp.com/send?phone=55${whatsapp}&text=${encodeURI(
          message
        )}`;
      },
    }
  })
  .component('v-select', vSelect)
  .component('upgrade-trigger', UpgradeTrigger)
  .component('inner-element', InnerElement)
  .mount("#app");
